import "./style.css";
export default function LogoExpenseAI_Large() {





    return (
        <>
        
            <a href="/#/Home/ExpenseAI" className="fw-bold no-decoration  mx-2 my-4 logo-css custom-font glow-css font-size-logo-large nowrap-text">Expense AI</a>
        </>
    )
}