import { HashRouter, Link,  useNavigate, Navigate, Routes, Route} from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import "./index.css";
import { menuList } from "../../ConstantVariables";

import { TfiMenu } from "react-icons/tfi";
import Dropdown from "./dropdown";
import { useDispatch } from "react-redux";
import { ExpenseAIState } from "../../store";
import { setMenuItem, setMenuItemDivLeft } from "./menuReducer";
import LogoExpenseAI_Large from "../../Logos/Logo_ExpenseAI.tsx/Logo_ExpenseAI_Large";

function MenuBar() {
    const[boolShowDropdownDiv, setBoolShowDropDownDiv] = useState(true);
    const dispatch = useDispatch();
    const menuItemWithEmptySublist = useSelector((state:ExpenseAIState)=>state.menuReducer.menuItemWithEmptySublist);
    // Get the navigate function from the useNavigate hook
    const navigate = useNavigate();
    const handleSignIn=()=> {
        navigate('/Account/Signin');  // Use navigate to change the route

    }
    const handleSignUp=()=> {
        navigate('/Account/Signup');
    }
   
    const [dropdowns, setDropdowns] = useState({ _id: "",
        name: ""});
    {/* Handle dropdown list when hovering over a menuItem on the MenuBar*/}
    const handleListItem = (menuItem:any) => {
        if (!menuItem.sublist || menuItem.sublist.length === 0) {
            navigate(`/Home/${menuItem.label}`);
        }
        {/*set the state of dropdown to menuItem before passing it into the Dropdown component*/}
        {dispatch(setMenuItem(menuItem))}
        
    };
    {/*  Function to deal with the passing of menuItem into Reducer when user hovers */}
    const handleMouseEnter =(menuItem:any, index:number) => {
        if (menuItem.sublist && menuItem.sublist.length != 0) {
        {/*Send the menuItem to the reducer in the store*/}

        dispatch(setMenuItem(menuItem));

        {/*send the position left data of the menuItem div to the reducer, so that it can be used by the upper level component. */}
        console.log(`the left of the menuItem is: ${document.getElementById(`divMenuItem${index}`)?.style.left}`);
        const element = document.getElementById(`divMenuItem${index}`);
        if (element) {
            const rect = element.getBoundingClientRect();
            dispatch(setMenuItemDivLeft(rect.left));
            console.log(`computed left position is: ${rect.left}`);
        }




    }
        else {
            dispatch(setMenuItem(menuItemWithEmptySublist));
            
            
           
            
        }
}

   
    
    return (

        
        
        <div className=" custom-row row-children-css d-flex">
            {/*This is the content inside of the top row in ExpenseAITemplate*/}
            <div id="colLogo" className="col text-start d-flex align-items-center in-line-css">

                <div className="d-flex d-block d-lg-none text-start m-2 ">
                    {/*  This is the bar icon that shows only when screen size is small */}
                <TfiMenu className="fw-bold text-white d-block d-lg-none text-start size-icon-css"/>

                </div>
                {/*left side col
                logo
                pricing
                menu
                */}
                <LogoExpenseAI_Large/>

            </div>
            <div id="divNavMenu" className="col d-flex d-none d-lg-flex show-only-when-lg-css custom-d-flex justify-content-start p-2 m-2 ">
                
            {menuList!=null && menuList.map((menuItem,index)=>(
                    <div className="menuItem-and-dropdown-div-css " >
                    <div   key={index}  id={`divMenuItem${index}`} className="d-flex mx-1 border-bottom-when-hover-css width-of-link-css menu-item-div">
                        {/*The div that wraps the  About Us link.  When hovered over, a thick link appears below it. */ }
                        {/*It also passes the menuItem as the state variable of dropdownMenuItem, so that later it can be passed into the Dropdown component*/}
                        <a onMouseEnter={() => handleMouseEnter(menuItem, index)}   onClick={()=>handleListItem(menuItem)} className="link-type-css m-1 " >{menuItem.name}</a>

                        
                        
                    </div >
                    
                    
                    </div>
                    

                    ))}
                
                    
                   

            </div>
            
            <div id="colButtons" className="col "> 
                <div id="innerColButtons" className="upRightDiv-position-css button-container py-2 ">
                    <button onClick={handleSignUp} id="button-try-for-free" className=" text-white mx-1 button-try-for-free-css fw-bold" type="button">Try for Free</button>
                    <button onClick={handleSignIn}   className=" button-width-css text-white mx-1 transparent-button fw-bold" type="button">Sign In</button>
                    
                </div>
                
            </div>
        </div>
        
       
     

    
    )
}

export default MenuBar;