import { configureStore } from "@reduxjs/toolkit";

import menuReducer from "../HomeTemplate/MenuBar/menuReducer";
import errorReducer from "../errorReducer";
import profileReducer from "../ExpenseAITemplate/LoggedIn_Components/Homepage_after_signin/profileReducer";
export interface ExpenseAIState {
menuReducer: {
    menuItemWithEmptySublist: any;
    menuItem: any;
    menuItemDivLeft:any;
    
};

//This is from the file errorReducer.ts in the outermost folder.
errorReducer: {
    errorMessage: any;
};
profileReducer: {
    profileData:any;
    companyName:string;
};
}

const store = configureStore({
    //include the reducer files together.
reducer: {
menuReducer,
errorReducer,
profileReducer,

}
});
export default store;