import React from 'react';
import logo from './logo.svg';
import {HashRouter} from "react-router-dom";
import { Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import './App.css';
import ExpenseAITemplate from './ExpenseAITemplate';
import store from './store';
import { Provider } from 'react-redux';
import AboutUs from './HomeTemplate/AboutUs';
import { productName } from './ConstantVariables';
import HomeTemplate from './HomeTemplate';
import SignIn_or_SignUp from './Account/User/signin_signup';
import Homepage_after_signin from './ExpenseAITemplate/LoggedIn_Components/Homepage_after_signin';
import ErrorReport from './ExpenseAITemplate/LoggedIn_Components/Homepage_after_signin/errorReport';



function App() {
  return (
    <Provider store={store}>
   
      
      <div className="App">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/Home"/>}/>
          
          <Route path="Home/*" element={<HomeTemplate/>}/>       {/* need the /* to be able to navigate to other route within the HomeTemplate by appending url to /Home/*/ }
          <Route path="/Account/Signin" element={<SignIn_or_SignUp/>}/>
          <Route path="/Account/Signup" element={<SignIn_or_SignUp/>}/>
          <Route path="/Account/Profile/:currentUserID/*" element={<Homepage_after_signin/>}/>
          <Route path="/errorMessage" element = {<ErrorReport/>}/>
        
            
          
            
        
          
        
        </Routes>
        </HashRouter>
      </div>
      
   
    </Provider>
  );
}

export default App;