 
import { useEffect } from "react";
import "./index.css";
import { useState } from "react";
import { MdLabelImportantOutline } from "react-icons/md";
function Intro(){

    
    {/*For the features section      */}
    const featureOfProduct = [
        {img: "",featureName:"Automatic Invoice Classification", description:"Leveraging our expertise in the Accounting Industry, our Machine Learning model was trained professionally by Accounting expert with deep insight in Business."}, 
        {img: "",featureName:"Automatic Accounting Journal Entry Support", description:"Seamless communication with 3rd-party Accounting Software API to allow immediate recording of Accounting Journal Entries."},
        {img: "",featureName:"Boost Efficiency", description:"Saves time for entreprenours to allow you to focus on things more important. Significantly Reduces Human Intervention and Costs"},
    ]
        //create an array of length of the length of featureOfProduct, and set it to be the initial value of activeStatus array.
    const initialArray = Array.from({ length: featureOfProduct.length }, () => false)
    const [activeStatus, setActiveStatus] = useState(initialArray);//[false, false, false]
         // Function to update a specific element in the array
    const setIsActive = (index:number, value:boolean) => {
        setActiveStatus(prevState => {
            // Create a new array with the updated value
            const newState = [...prevState];
            newState[index] = value;
            return newState;
        });
    };
    {/*to access whether user has scrolled to the row of a feature in the feature div*/}
    
    
    //if user has scrolled to the row of a feature in the feature div (meaning the div has come to the center of screen virtically), set activeStatus to true.
    //if user past 
    useEffect(()=>{
        function checkScrollPositionToActiveRow() {
            
            //function isActive(index:number)
                //if the activeStatus[index] is true, then it is active.
                featureOfProduct.forEach((item, index) => {

                //if the top of any one of the independent small feature div reaches the middle of the screen, set the activeStatus of that div to be true, so that the color of the text can be normal.
                //formula:   if top of independent feature element div === (the Y value of top of the scrolled viewport + 50% of height of user screen)
                const element = document.getElementById(`divFeatureRow-${index}`);
                if (element) {
                    const rect = element.getBoundingClientRect();
                    const elementMiddle = rect.top + rect.height / 2;
                    const viewportMiddle = window.innerHeight / 2;

                    if (elementMiddle >= viewportMiddle * 0.8 && elementMiddle <= viewportMiddle * 1.2) {
                        setIsActive(index, true);
                    } else {
                        setIsActive(index, false);
                    }
                }
            });
        };
        window.addEventListener('scroll',checkScrollPositionToActiveRow);
        window.addEventListener('resize', checkScrollPositionToActiveRow);

        return () => {
            window.removeEventListener('scroll', checkScrollPositionToActiveRow);
            window.removeEventListener('resize', checkScrollPositionToActiveRow);
        };
        
    },[]);

    
 
    return(
        
        <div className="container-fluid background-color-css text-top-layer-css text-white ">
           
            <div id="divAd1" className="d-container-fluid justify-content-center text-border-css">
            
                {/* A row - Topic Div */}
                <div id="divTopic1" className="row height-screen-ad-css ">
                    <div className="col align-self-center">
                    <h1 className="font-title-css "><span className="text-lg-css text-print-black">Automated Expense Reimbursement </span><br/><span className="text-sm-css text-print-black">Using AI Technology</span></h1>
                    </div>
                </div>
                {/* A row -  feature Div */}
                <div id="div-featureSummary-1" className="row  mt-4">
                    <div id="innerDiv-featureSummary-1" className=" col align-self-center p-2  ">




                        {featureOfProduct.map((item, index)=>(
                            <>
                            {/*small div for independent feature*/}
                             {/*text color depend on position*/}
                            <div id={`divFeatureRow-${index}`} key={index} className={`direction-adjustable-row-css py-4 text-secondary ${activeStatus[index]? "text-white ":""}`}>
    
                                <div className="col-2">
                                    <span className="icon-feature-css align-items-start"><MdLabelImportantOutline /></span>
                                </div>
                                <div className="col-4 feature-text-lg-css ">
                                    <h2 >{item.featureName}  </h2>
                                </div>
                                <div className="col-6 feature-text-sm-css">
                                    {item.description} 
                                </div>
                                
                            </div>
                            </>
                        ))}
                        
                    
                        
                    </div>
                </div>

                {/* A row */}
                <div id="divDivider" className="row height-screen-ad-css ">
                    <div className="col align-self-center">
                    <h1 className="font-title-css "><span className="text-lg-css">"Join to Save Time on Expense Accounting Process"</span></h1>
                    </div>
                </div>
                
                    
                       
                   
                    

            </div>
        </div> 
            
        
    )
}
export default Intro;