import "./index.css";
import React, { useEffect } from "react";
import { UseSelector, useDispatch, useSelector } from "react-redux";
import { ExpenseAIState } from "../../store";
import { setMenuItemDivLeft } from "./menuReducer";
import { useNavigate } from "react-router";

function Dropdown (menuItem:any) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const menuItemDivLeft = useSelector((state:ExpenseAIState)=>state.menuReducer.menuItemDivLeft);
    {/*Purpose: set the left property for the style of the hovered menuItem*/}
    useEffect(()=>{
        {/* Get the div element that has the id of the Dropdown div*/}
        const dropdownDiv = document.getElementById("dropdownDivBelowMenuList");
        {/*set the left css property to be the menuItemDivLeft px in reducer in the store*/}
        if (dropdownDiv) {
            {console.log(`left in Dropdown component: ${menuItemDivLeft}`)}
            dropdownDiv.style.left = `${menuItemDivLeft}px`;
        }
        
    }, [menuItemDivLeft]);


    return (
        <div id="dropdownDivBelowMenuList" className=" dropdown-inner-div-css   text-white">
            {/*
            <div className="dropdown-background-css position-dropdown-css  mt-4 mx-1 text-dropdown-css">
        
        {menuItem.sublist && menuItem.sublist.map((sub:any, index:number) => (
                <div key={index} className="d-flex dropdown-div-css ">
                    <a className="text-style-sublist-css m-2 ">{sub.name}</a>
                </div>
            ))}
        </div>    */}
        {menuItem.menuItem.sublist && menuItem.menuItem.sublist.map((sub:any, index:number) => (
            
            <div key={index} className="dropdown-div-css m-2">
            <a onClick={()=>navigate(`${sub.urlName}`)} className="link-type-css">{sub.name}</a>
            </div>
                
                
                
            ))}
            

        </div>
        



    )
       
}
export default Dropdown;