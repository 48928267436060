import { useState, useEffect } from "react";
import MenuBar from "./MenuBar";
import Dropdown from "./MenuBar/dropdown";
import { setMenuItem } from "./MenuBar/menuReducer";
import { useDispatch, useSelector } from "react-redux";
import  { ExpenseAIState } from "../store";
import { HashRouter, Link,  useNavigate, Navigate, Routes, Route} from "react-router-dom";
import ExpenseAITemplate from "../ExpenseAITemplate";
import { productName } from "../ConstantVariables";
import "./index.css";
import Intro from "./Intro";
import AboutUs from "../HomeTemplate/AboutUs";
function HomeTemplate(){

    const [isShrunk, setShrunk] = useState(false);
    const dispatch = useDispatch();

    const menuItem = useSelector((state:ExpenseAIState)=>state.menuReducer.menuItem);
    const menuItemWithEmptySublist = useSelector((state:ExpenseAIState)=>state.menuReducer.menuItemWithEmptySublist);

    useEffect(() => {
        const handleScroll = () => {
            setShrunk(window.scrollY > 1); // Activates black background className in NavBar effect if scrollY > 1
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        
        handleScroll(); // Call it initially to set the height based on the initial scroll position

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, []);



    return (
        <>
        <div className="background-home-css">
        {/* Navbar on top of the video */}
            {/* when mouse leaves the entire div, set the menuItem in the reducer store to be the menuItemWithEmptySublist. */}
            <div id="navbarDiv" onMouseLeave={()=>dispatch(setMenuItem(menuItemWithEmptySublist))} className={` container-fluid  p-2 m-0  navBar ${isShrunk ? 'black-background' : 'transparent-background'}`}>
                    <MenuBar/>
                    {/*The dropdown div below the NavBar*/}
                    <Dropdown  menuItem={menuItem}/>

                    
            </div>
            <div>
                <Routes>
                    <Route path="/" element={<Intro/>}/>
                    <Route path="AboutUs" element={<AboutUs/>}/>
                
                    <Route path="ExpenseAI" element={<ExpenseAITemplate/>}/>
                   
                </Routes>
            </div>
            <div>
                
            </div>






        </div>
        </>
        
    )
}


export default HomeTemplate;