{/*Public Constant Data */}


{/*Home page list of menu items */}
export const menuList = [
    {"_id":"menuList000001","name":"About Us", "label":"AboutUs", "sublist":[] },
    {"_id":"menuList000002","name":"Products", "label":"Products","sublist":[{"_id":"menuList000021","name":"Expense AI", "urlName":"ExpenseAI"}]},
    {"_id":"menuList000003","name":"Services", "label":"Services","sublist":[
                                    {"_id":"menuList000031","name":"ML Dataset Labeling by Accounting Professional","label":"labeling","sublist":[]}, 
                                    {"_id":"menuList000032","name":"ML Model Training", "label":"modelTraining","sublist":[{"_id":"menuList000031","name":"test","label":"labeling","sublist":[]}]}
                                  ]},
    {"_id":"menuList000004","name":"Publications", "label":"Publications","sublist":[{"_id":"menuList000041","name":"Articles", "label":"Articles","sublist":[]}]}

]


export const companyName = [
    {id:"company0001", name:"Looomiie", urlName:"Looomiie", address:"500 8th Avenue FRNT 3, Manhattan, NY 10018", email:"contact@Looomiie.com"},
]

export const productName = [{
    id:"product0001", name:"Expense AI", urlName:"ExpenseAI",
}]
export const founderName = [
    {id:"founder0001", name:"Yiwei Wang"},
]

