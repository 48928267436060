import { useEffect } from "react";
import "./index.css";
import { companyName } from "../../ConstantVariables";

function Intro(){


    useEffect(()=>{
        const topicInVideo = document.getElementById("topic-in-video");
        if(topicInVideo){
            topicInVideo.style.fontSize = "30px";
            
        }
        
    },[]);
    return(
        <div className="container-fluid background-color-css text-top-layer-css text-white">
           
            <div id="divAd1" className="d-flex text-border-css">
            
                {/* A row */}
                <div className="row">
                    <div className="col">
                    <h1 className="font-title-css">{`${companyName.find(item=>item.id === "company0001")?.name}, where innovation meets efficiency.`}</h1>
                <h3>            Automatically Recognizes and Classifies Invoices Submitted by Staffs   </h3>
                <h3>                        Helps Organizations Automatically Record Accounting Journal Entries</h3>
                <h3>                                    Efficiently and Significantly Reduces Human Intervention and Costs</h3>
                   
                    
                       
                   
                    </div>

                

                </div>

            </div>
        </div> 
        
    )
}
export default Intro;