
import "./index.css";
import image_background from "../Asset/image/image_background.jpeg";

import Intro from "./Intro";
import { useState, useEffect } from "react";
import { UseDispatch, useSelector } from "react-redux";
import InfoPageTail from "../InfoSection/InfoPageTail";





function ExpenseAITemplate() {
    
    const [variance, setVariance] = useState(10); // Initial full height
    const [lastScrollTop, setLastScrollTop] = useState(0);

    
    

    

     {/*Word  on video */}
    useEffect(()=>{
        const topicInVideo = document.getElementById("topic-in-video");
        if(topicInVideo){
            topicInVideo.style.fontSize = "30px";
            
        }
        
    },[]);
    {/************************************************************************* */}
    useEffect(() => {
        const handleScroll = () => {
            
            const rootDocumentElement = document.documentElement;
            const scrollTop = rootDocumentElement.scrollTop;
            const clientHeight = rootDocumentElement.clientHeight;

            const scale = Math.max(0.5, Math.min(1, 1 - scrollTop / clientHeight));
            const opacity = scale; // Use the same value for opacity to achieve fade effect

            rootDocumentElement.style.setProperty('--video-scale', scale.toString());
            rootDocumentElement.style.setProperty('--video-opacity', opacity.toString());

            const videoWrapperDiv = document.getElementById('videoWrapper');
            if (videoWrapperDiv) {
                videoWrapperDiv.style.transform = `scale(${scale})`;
                videoWrapperDiv.style.opacity = opacity.toString();
            }

            setLastScrollTop(scrollTop);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);

        handleScroll(); // Call it initially to set the height based on the initial scroll position

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, [lastScrollTop]);

    {/************************************************************ */}
    
    return (
        <>
        
        
        <div className="background-black-css">
            
            <div className="video-wrapper" id="videoWrapper">
                {/*Word  on video */}
                <div className="topic-in-video container-fluid">
                    <div className="row  element-in-center-css">
                        <span className="col align-self-center font-topic-lg-css " >Automated </span>
                    </div>
                        
                    <div className="row  ">
                        <span className="col font-topic-md-css">Expense Reimbursement</span>
                    </div>
                    
                </div>
                <video autoPlay muted loop id="backgroundVideo"  className="video-frame" >
                    {/*Getting video directly from the public folder    */}
                    <source  src="/Asset/video/robot_sleek_clean.mp4" type="video/mp4"/>
                </video>
                
                
            </div>
            
        </div>

        

           
            {/* Main content container */}
            <div className="container-fluid p-0">
                <Intro/>
            </div>
            {/* Page tail container */}
            <div className="container-fluid">
               <InfoPageTail/>
            </div>

           

            
        

    </>
    
    )

    

}

export default ExpenseAITemplate;