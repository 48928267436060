import axios from "axios"
import {BASE_API} from "../../../Account/User/client"

export const PROFILE_API = `${BASE_API}/users/profile`;
export const getUserProfile=async (userID: string)=>{
    console.log(`testii ${userID}`);    
    const response = await axios.get(`${PROFILE_API}`, 
    
        //configureration JSON object for the HTTP request
        {
        //params: { userID },  // Pass userID as a query parameter
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true// to Include cookies if  backend sets them
    }
    );
    console.log(`user profile received from backend: ${JSON.stringify(response)}`);
    console.log(` response.data is: ${JSON.stringify(response.data)}`);
    //return profile JSON
    return response.data
}




