import { UseSelector, useSelector, useDispatch} from "react-redux";
import { useEffect } from "react";
import { ExpenseAIState } from "../../../store";
import { error } from "console";

import { resetError } from "../../../errorReducer";
import { useNavigate } from "react-router-dom";

function ErrorReport () {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const errorMessage = useSelector((state:ExpenseAIState) => state.errorReducer.errorMessage.error);
    console.log(`errorMessage from redux:${errorMessage}`);
    useEffect(() => {
        // Set up a timer to redirect after 10 seconds
        const timer = setTimeout(() => {
            navigate('/Account/Signin'); // Redirect to sign-in page
        }, 10000); // 10 seconds

        // Clear the error message when the component unmounts or redirects
        return () => {
            clearTimeout(timer);
            dispatch(resetError());
        };
    }, []);

 
    return (
        <>
        <div>
            <h1>Something went wrong</h1>
            <p>Error Message:  {errorMessage}</p><br/>
            <p>Redirecting back to Sign In page in 10s...</p>
        </div>
        
        </>
    )
}

export default ErrorReport;