import { createSlice } from "@reduxjs/toolkit";

//import { modules } from "../../Database";
const initialState = {
menuItemWithEmptySublist:{"_id":"menuListSample000001","name":"", "label":"", "sublist":[] },
menuItem: {"_id":"menuListSample000001","name":"", "label":"", "sublist":[] } ,
menuItemDivLeft: {},


};
const menuSlice = createSlice({
    name: "menuItem",
    initialState,
    reducers: {
    setMenuItem: (state, action) => {state.menuItem = action.payload;},
    setMenuItemDivLeft:(state, action)=>{state.menuItemDivLeft = action.payload;},  
            },
            });
        export const { setMenuItem, setMenuItemDivLeft } = menuSlice.actions;
        export default menuSlice.reducer;