import LogoExpenseAI_Large from "../../../Logos/Logo_ExpenseAI.tsx/Logo_ExpenseAI_Large";
import './index.css';
import * as client from "./client";
import {  useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import ErrorReport from "./errorReport";
import { setError } from "../../../errorReducer";

import { useEffect } from "react";
import { useState } from "react";
import { ExpenseAIState } from "../../../store";
import { setProfile} from "./profileReducer";

function Homepage_after_signin() {
    const dispatch = useDispatch();
    //Get the userID from the url.
    const { currentUserID } = useParams();
    const userProfileInReducer = useSelector((state:ExpenseAIState)=>state.profileReducer.profileData);
    const userProfile = userProfileInReducer.profile;
    const userCompanyName = userProfileInReducer.company_name;
    const navigate = useNavigate();


    useEffect(()=> {
        
        //if userID exists, get the user's profile data.
        if (currentUserID) {

            console.log(`currentUserID is : ${currentUserID}`);
            const fetchUserProfile = async (id:string) => {
                try {
                    /*input userid to get the following data:
                       1. user profile JSON object
                       2. user companyName string */
                    const userProfileResponseReceived = await client.getUserProfile(id);
                    console.log(`### user profile : ${JSON.stringify(userProfileResponseReceived)}`);
                    
                    dispatch(setProfile(userProfileResponseReceived));
                    
                    
                }
                catch (error) {
                    //if error occors, navigate to error page.
                    console.log(`error: ${error}`);
                    dispatch(setError(error));
                    navigate(`/errorMessage`);
                }
            };
            
            fetchUserProfile(currentUserID);
           
            }
            }
            
        ,[currentUserID,setProfile]);

    
    return(
        <>
        <div className="container-fluid full-screen-css background-color-signin ">
            <div className="row"> 
                <div className="col-2 p-2">
                    {/*Product Logo*/}
                    <LogoExpenseAI_Large/>
                </div>
                
            </div>
            {/*The div that shows the user profile*/}
            <div className="profile-div-frame text-black">
               
                {userProfile  && userProfile.firstName ? (
                            
                            <p className="font-weight-hello-css profile-greating-css custom-font m-2">Hello! {userProfile.firstName}</p>
                        ) : (
                            <span className="">Loading profile...</span> // Display this while loading
                        )}
                
                <div className="container m-2">
                    <div className="row ">
                        <div className="col-4">
                          <span className="float-start fw-bold ">Profile: </span>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-4 text-end">
                        Employee ID:
                        </div>
                        <div className="col-8 text-start">
                        {userProfile.employee_id}
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-4 text-end">
                        Email:
                        </div>
                        <div className="col-8 text-start">
                        {userProfile.email}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 text-end">
                        Company:
                        </div>
                        <div className="col-8 text-start">
                        {userCompanyName}
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-4 text-end">
                        Title:
                        </div>
                        <div className="col-8 text-start">
                        {userProfile.title}
                        </div>
                        
                    </div>
                </div>
                
                

            </div>
            {/*the first container that holds the invoice info and the extracted info for user submission*/}
            <div className="frame-backgroud-color-white ">
                {/*invoice section for current expense to be claimed*/}
                <div className = "row  ">
                    
                        
                        {/*the right side invoice info input  section*/}
                        <div className="col-10">
                            <div className="container" >
                                <div className="row">
                                    <div className="col-6">
                                        <label className="p-2 float-end fw-bold" htmlFor="INPUTuploadPDF">Upload Invoice:</label>
                                    </div>
                                    <div className="col-6">
                                        <input className="p-2 float-start" type="file" id="INPUTuploadPDF" ></input>
                                    </div>
                                    

                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <label className="p-2 float-end fw-bold" htmlFor="INPUTinvoiceamount">Amount Claiming:</label>
                                    </div>
                                    <div className="col-6">
                                        <div className="currency-input-container">
                                            <select className="p-1 m-2">
                                                <option value="USD" className=" " >$</option>
                                                <option value="EUR" className="" >&</option>
                                            </select>
                                            <input className="float-start width-small-css" type="number" ></input>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <label className="p-2 float-end fw-bold" htmlFor="INPUTcostcenter">Cost Center:</label>

                                    </div>
                                    <div className="col-6">
                                        <select className="p-2 m-2 float-start">
                                            <option value="project1" className=" " >Project 1</option> {/*to replace*/}
                                            <option value="project2" className="" >Project 2</option> {/*to replace*/}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                            
                            
                            
                        </div>
                        <div className="col-2 border-style-divider-css">
                            <div className="container ">
                                <div className="row fw-bold">
                                    Preview:
                                </div>
                            </div>
                        </div>
                    
                </div>
            </div>
                {/*menu section at bottom*/}
            <div className=" frame-backgroud-color-white ">
                <div className="row p-2">
                
                        <div className="col min-width-small-item">
                            <button className="btn text-black fw-bold">Claim History</button>
                        </div>
                </div>
                <div className="row p-2">
            
                        <div className="col min-width-small-item">
                            <div className="container">
                                <div className="row">
                                    <div className="col fw-bold">
                                        Claim ID
                                    </div>
                                    <div className="col fw-bold">
                                        Claim Date
                                    </div>
                                    <div className="col fw-bold">
                                        Expense Category
                                    </div>
                                    <div className="col fw-bold">
                                        Status
                                    </div>
                                    <div className="col fw-bold">
                                        PDF
                                    </div>
                                </div>

                                {/*the data detail row*/}
                                <div className="row">
                                    <div className="col">
                                        Claim ID
                                    </div>
                                    <div className="col">
                                        Claim Date
                                    </div>
                                    <div className="col">
                                        Expense Category
                                    </div>
                                    <div className="col">
                                        Status
                                    </div>
                                    <div className="col">
                                        PDF
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>

            </div>
            

        </div>
    
        </>
    )
};


export default Homepage_after_signin;