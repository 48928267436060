import { createSlice } from "@reduxjs/toolkit";

//import { modules } from "../../Database";
const initialState = {

errorMessage: { error: "Error Message Placeholder" },

};
const errorSlice = createSlice({
    name: "error",
    initialState,
    reducers: {
    setError: (state, action) => {state.errorMessage.error = action.payload;},
    resetError:(state) =>{state.errorMessage.error = "Error Message Placeholder" },

            }
        });
        export const { setError, resetError } = errorSlice.actions;
        export default errorSlice.reducer;