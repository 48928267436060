import "./index.css";
import { companyName, founderName } from "../../ConstantVariables";
import InfoPageTail from "../../InfoSection/InfoPageTail";
import founderPortrait from '../../Asset/image/founder_portrate.jpg';
function AboutUs() {


    const company = companyName.find(item=>item.id === "company0001");
    const founder = founderName.find(item=>item.id==="founder0001");
    const aboutUsContent = [
        {id:"aboutUs0001", name:"ABOUT US", description:`Created for entrepreneurs struggling with the intricacies of accounting and company management.   ${company?.name} is here to transform your business journey into a seamless and efficient experience.`}, 
        {id:"aboutUs0002", name:"Background", description:`${company?.name} is founded by an experienced Certified Public Accountant with dual Master’s degrees in Accounting and Computer Science. By combining expertise from two dynamic fields, our firm is dedicated to transforming the way entrepreneurs manage their businesses.`}, 
        {id:"aboutUs0003", name:"Professional Excellence", description:"Backed by extensive experience from top US accounting firm, YW Corp possesses an exceptional understanding of financial practices and regulatory standards. This solid foundation guarantees that every service we provide is rooted in accuracy, reliability, and excellence."}, 
        {id:"aboutUs0004", name:"Innovative Vision", description:`Passionate about empowering entrepreneurs in their businesses, ${company?.name}'s goal is to simplify and streamline business management. By leveraging Natural Language Processing (NLP) and Machine Learning technologies, ${company?.name}  developed AI-driven product that automates invoice reimbursement and recording procedures, saving time and reducing stress for entrepreneurs,making financial management effortless, allowing you to focus on what truly matters—growing your business.`},
        {id:"aboutUs0005", name:"A Commitment to Success", description:`At ${company?.name}, our mission is to provide entrepreneurs with the tools and support needed to thrive in a competitive market. Under [Your Name]’s visionary leadership, we are committed to making financial management effortless, allowing you to focus on what truly matters—growing your business.`},
        {id:"aboutUs0006", name:"", description:`Join us on this journey and discover how ${company?.name} can transform your business operations with unparalleled expertise and cutting-edge technology.`},
        {id:"aboutUs0007", name:"", description:"Empowering Entrepreneurs. Simplifying Success."}

    ]
    return (
        <>
        <div className="container-fluid background-color-AboutUs-css justify-content-center-css">
            {/*div for page content */}
            <div id="divAboutUs1" className=" row  divAboutUs1 padding-top-page-non-video-css text-color-css text-style1-css ">
                <h1 className="text-color-css letter-spacing2-css  mt-4">ABOUT US</h1>
                <div className= "row  div-width-aboutUs-css padding-responsive-text-css">
                    
                    <div className="col-12  align-self-center responsive-text-div-css">{aboutUsContent.find(item=>item.name==="ABOUT US")?.description} </div>
                    
                    
                </div>

                <div className="row  padding-responsive-text-css    ">
                    <span className="text-color-css text-size-secondary-center-title padding-bottom-css-5px mt-4">Background</span>
                    
                    <div className="col-12  align-self-center responsive-text-div-css">{aboutUsContent.find(item=>item.name==="Background")?.description}</div>
                </div>

                <div className="row    padding-responsive-text-css">
                    <span className="text-color-css text-size-secondary-center-title padding-bottom-css-5px margin-top-about-row-css mt-4">Professional Excellence</span>
                    
                    <div id="divImage" className="col-12  align-self-center responsive-text-div-css"> {aboutUsContent.find(item=>item.name==="Professional Excellence")?.description}</div>
                    {/*founder portrate*/}
                    <div  className="col-12 text-center m-4">
                        <img src={founderPortrait} alt="[founder portrate]" className=" founder-portrate1 center-image" ></img>
                    </div>
                </div>

                <div className="row     padding-responsive-text-css">
                    <span className="text-color-css text-size-secondary-center-title padding-bottom-css-5px mt-4">Innovative Vision</span>
                    
                    <div className="col-12   align-self-center"> {aboutUsContent.find(item=>item.name==="Innovative Vision")?.description}</div>
                </div>
                {/* ending */}
                <div className="row     padding-responsive-text-css">
                    
                    
                    <div className="col-12  align-self-center"> {aboutUsContent.find(item=>item.id==="aboutUs0006")?.description}</div>
                </div>


            </div>


            <div>
                <InfoPageTail/>
            </div>
        </div>
        </>


    )
}

export default AboutUs;