//current user's profile reducer
import { createSlice } from "@reduxjs/toolkit";

//import { modules } from "../../Database";
const initialState = {

profileData: {profile:
    { employee_id: "", company_id:"", username:"", email:"", role:"", firstName:"", lastName:"", dateOfBirth:"", is_active:false, user_id:"", claims:[], title:"", discription:""  }
    ,company_name: ""}


};
const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        //here, I did not separately copy the data for each element, instead, profile is copied directly into the profileData
        setProfile: (state, action) => {
            console.log(`state before update: ${JSON.stringify(state)}`);
            console.log(`action.payload: ${JSON.stringify(action.payload)}`);
            state.profileData = action.payload;
            console.log(`state after update: ${JSON.stringify(state)}`);
        },
        resetProfile:(state) =>{state.profileData = {profile:
            { employee_id: "", company_id:"", username:"", email:"", role:"", firstName:"", lastName:"", dateOfBirth:"", is_active:false, user_id:"", claims:[], title:"", discription:""  }
            ,company_name: ""}
        },
        
    },
        
    
        });
        export const { setProfile, resetProfile } = profileSlice.actions;
        export default profileSlice.reducer;